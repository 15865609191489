import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Button from '../components/Button'
import Header from '../components/Header/Header'
import CustomLink from '../components/__general/CustomLink/CustomLink'
import '../css/index.css'
import { Spacer } from '../theme/base'
import { Headline1, Title2, Title3 } from '../theme/typography'
import { parseContentfulFileUrl } from '../util/helpers'

const query = graphql`
  query {
    contentfulAsset(title: { eq: "404" }) {
      file {
        url
      }
    }
  }
`

const Video = styled.video`
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const MissingPage = () => {
  const { contentfulAsset } = useStaticQuery(query)
  const video = parseContentfulFileUrl(contentfulAsset.file.url)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404</title>
        <meta name="description" content="Page not found" />
        <meta name="robots" content="noindex" />
        <html lang="en" />
      </Helmet>
      <main style={{ position: 'relative' }}>
        <Video autoPlay loop muted>
          <source src={video} />
          <track
            src="../../../assets/captions/black-void.vtt"
            kind="captions"
            label="english_captions"
          />
        </Video>
        <Header transparent />
        <div
          style={{
            height: '100vh',
            alignItems: 'center',
            backgroundColor: 'black',
          }}
        >
          <Container style={{ height: '100%' }}>
            <Row
              style={{
                height: '100%',
                alignItems: 'center',
              }}
            >
              <Col lg={6}>
                <Title2 accent>404</Title2>
                <Spacer h08 />
                <Title3 accent>A black void appeared!</Title3>
                <Spacer h40 />
                <Headline1 accent>
                  Oops, seems that there’s nothing in here. The page you’re looking for doesn’t
                  exist.
                </Headline1>
                <Spacer exact={64} />
                <CustomLink to="/">
                  <Button title="Take me home" />
                </CustomLink>
              </Col>
            </Row>
          </Container>
        </div>
      </main>
    </>
  )
}

export default MissingPage
